<template>
  <div>
    <b-row class="row mb-4 mt-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="d-flex justify-content-between">
          <h3>{{ $t('settings.analytics.name') }}</h3>
          <date-range-picker
            ref="picker"
            opens="left"
            :locale-data="localeData"
            :minDate="minDate"
            :singleDatePicker="false"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
          >
            <template v-slot:input="picker">
              <div style="min-width: 350px;">
                {{ picker.startDate | inputDate }} - {{ picker.endDate | inputDate }}
                <b-icon class="pl-1 dropdown-icon" icon="caret-down-fill" aria-hidden="true"></b-icon>
              </div>
            </template>
          </date-range-picker>
        </div>
        <hr>
        <p v-html="$t('settings.analytics.mergedDesc')"></p>
      </b-col>
    </b-row>
    <b-row v-if="showGroupDomainWarning" class="row mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-alert show variant="info" class="d-flex align-items-center">
          <b-icon icon="exclamation-triangle-fill" aria-hidden="true" class="mr-2"></b-icon>
          <div class="text-center" style="flex: 1;">
            {{ $t('settings.analytics.domainWarning') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="row mb-4 mt-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row v-if="queryErrors.globalStats" class="global-stats mb-5">
          <b-col cols="12">
            <h4>Global stats</h4>
            <div class="query-error">
              {{ $t('settings.analytics.globalStatsQueryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="globalStats" class="row global-stats mb-5">
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.visitors') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-visitors"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-visitors">
                {{ $t('settings.analytics.visitorsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.visitors }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.views') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-views"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-views">
                {{ $t('settings.analytics.viewsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.views }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.avgTime') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-avg-time"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-avg-time">
                {{ $t('settings.analytics.avgTimeTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.avgTime | timeString }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5 id="global-bounce-rate">
              {{ $t('settings.analytics.bounceRate') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-bounce-rate"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip target="tooltip-global-bounce-rate">
                {{ $t('settings.analytics.bounceRateTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.bounceRate }}</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.readsPerDay') }}</h4>
        <div v-if="queryErrors.readsPerDayObject" class="query-error">
          {{ $t('settings.analytics.readsPerDayQueryError') }}
        </div>
        <LineChart
          v-else-if="readsPerDayObject"
          :key="readsPerDayKey"
          :reads-per-day-object="readsPerDayObject"
          :dateRange="dateRange"
        />
        <p v-else>{{ $t('settings.analytics.noReadsPerDay') }}</p>
      </b-col>
    </b-row>
    <b-row v-if="queryErrors.projectOrFolders" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.projectOrFolders') }}</h4>
        <div class="query-error">
          {{ $t('settings.analytics.projectOrFoldersQueryError') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="itemType === 'folder' && projectOrFolders.length" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>
        <b-table
          :key="folderOrProjectTableKey"
          :fields="projectAndFolderFields"
          :items="projectOrFolders"
          borderless
          dark
          fixed
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data">
            <div
              v-if="data.item.type === 'folder'"
              class="cell-folder cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon icon="folder" aria-hidden="true" scale="1"></b-icon>
            </div>
            <div
              v-else-if="data.item.type === 'project' && !data.item.image"
              class="cell-background-image d-flex justify-content-center align-items-center"
            >
              <b-icon icon="files" aria-hidden="true" scale="1"></b-icon>
            </div>
            <div
              v-else-if="data.item.type === 'project' && data.item.image"
              class="cell-background-image"
              :style="`background-image: ${data.item.image}`"
            ></div>
          </template>
          <template #cell(avgReadingTime)="data">
            {{ data.item.avgReadingTime | timeString }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="queryErrors.pages" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="5">
        <h4>{{ $t('settings.analytics.pages') }}</h4>
        <div class="query-error">
          {{ $t('settings.analytics.pagesQueryError') }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="pages && pages.length" class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.pages') }}</h4>
        <b-table
          :key="pageTableKey"
          :fields="fields"
          :items="pages"
          borderless
          dark
          fixed
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data">
            <div
              class="cell-background-image"
              :style="{
                backgroundImage: data.item.imageObject.imageUrl,
                backgroundPosition: data.item.imageObject.focalpoint,
              }"
            ></div>
          </template>
          <template #cell(name)="data">
            <div class="name-cell">
              <div class="name-wrapper">
                {{ data.item.name }}
              </div>
            </div>
          </template>
          <template #cell(viewers)="data">
            <div class="viewers-cell">
              <div class="viewers-wrapper">
                {{ data.item.viewers }}
              </div>
            </div>
          </template>
          <template #cell(views)="data">
            <div class="views-cell">
              <div class="views-wrapper">
                {{ data.item.views }}
              </div>
            </div>
          </template>
          <template #cell(avgReadingTime)="data">
            <div class="avg-reading-time-cell">
              <div class="avg-reading-time-wrapper">
                {{ data.item.avgReadingTime | timeString }}
              </div>
            </div>
          </template>
          <template #cell(bounceRate)="data">
            <div class="bounce-rate-cell">
              <div class="bounce-rate-wrapper">
                {{ data.item.bounceRate }}
              </div>
            </div>
          </template>
          <template #cell(entries)="data">
            <div class="entries-cell">
              <div class="entries-wrapper">
                {{ data.item.entries }}
              </div>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <h4>{{ $t('settings.analytics.referrers') }}</h4>
        <div v-if="queryErrors.referrers" class="query-error">
          {{ $t('settings.analytics.referrersQueryError') }}
        </div>
        <b-table-simple v-else-if="mergedReferrers.length" hover dark>
          <b-thead>
            <b-tr>
              <b-th>{{ $t('settings.analytics.referrers') }}</b-th>
              <b-th>{{ $t('settings.analytics.visitors') }}</b-th>
              <b-th>{{ $t('settings.analytics.views') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="referrer in mergedReferrers" :key="referrer.referrer">
              <b-td>{{ referrer.referrer }}</b-td>
              <b-td>{{ referrer.visitors }}</b-td>
              <b-td>{{ referrer.views }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <p v-else>{{ $t('settings.analytics.noReferrers') }}</p>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="5">
        <h4>{{ $t('settings.analytics.deviceType') }}</h4>
        <div v-if="queryErrors.devices" class="query-error">
          {{ $t('settings.analytics.devicesQueryError') }}
        </div>
        <b-table-simple v-else-if="mergedDevices.length" hover dark>
          <b-thead>
            <b-tr>
              <b-th>{{ $t('settings.analytics.deviceType') }}</b-th>
              <b-th>{{ $t('settings.analytics.visitors') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="device in mergedDevices" :key="device.device">
              <b-td>{{ device.device }}</b-td>
              <b-td>{{ device.visitors }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <p v-else>{{ $t('settings.analytics.noDevices') }}</p>
      </b-col>
      <b-col cols="12" sm="5">
        <h4>{{ $t('settings.analytics.countries') }}</h4>
        <div v-if="queryErrors.countries" class="query-error">
          {{ $t('settings.analytics.countriesQueryError') }}
        </div>
        <b-table-simple v-else-if="countries.length" hover dark class="country-table">
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th>{{ $t('settings.analytics.readers') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="country in countries" :key="country.name">
              <b-td>{{ country.name }}</b-td>
              <b-td>{{ country.readers }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <p v-else>{{ $t('settings.analytics.noCountries') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import GetFathomData from '@/graphQlQueries/queries/getFathomData';
import GetPirschData from '@/graphQlQueries/queries/getPirschData';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import LineChart from '@/components/settings/analytics/LineChart.vue';
import GetAllChildPageIdsOfFolder from '@/graphQlQueries/queries/getAllChildPageIdsOfFolder';
import GetAllChildPageIdsOfProject from '@/graphQlQueries/queries/getAllChildPageIdsOfProject';
import thumbDefault from '@/assets/thumb-default.png';
import GetAllFoldersAndProjectsByFolderId from '@/graphQlQueries/queries/getAllFoldersAndProjectsByFolderId';

export default {
  name: 'MergedAnalyticsDashboard',
  components: { DateRangePicker, LineChart },
  props: {
    slugPathFromUrl: {
      type: String,
      default: null,
    },
    groupDomain: {
      type: String,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    pirschCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      queryErrors: {
        globalStats: false,
        readsPerDayObject: false,
        referrers: false,
        devices: false,
        countries: false,
        pages: false,
        projectOrFolders: false,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      minDate: null,
      globalStats: null,
      localeData: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: this.$t('buttons.apply'),
        cancelLabel: this.$t('buttons.cancel'),
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0,
      },
      path: null,
      timezone: 'Europe/Berlin',
      switchDate: new Date('2024-04-01T00:00:00'),
      fathomDays: 0,
      pirschDays: 0,
      fathomPercentage: 0,
      pirschPercentage: 0,
      readsPerDayObject: null,
      readsPerDayKey: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
      mergedReferrers: [],
      mergedDevices: [],
      countries: [],
      fieldCountries: [
        {
          key: 'name',
          label: '',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'readers',
          label: this.$t('settings.analytics.readers'),
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      pages: [],
      pageTableKey: 0,
      fields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'viewers',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'avgReadingTime',
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'entries',
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      projectOrFolders: [],
      folderOrProjectTableKey: 0,
      projectAndFolderFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'entries',
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'avgReadingTime',
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
    };
  },
  computed: {
    showGroupDomainWarning() {
      const startDate = new Date(this.dateRange.startDate);
      const switchDate = new Date(this.switchDate);
      return (startDate < switchDate) && this.groupDomain.includes('webmag');
    },
  },
  created() {
    this.initializeDates();
    this.path = this.getPath();
  },
  methods: {
    initializeDates() {
      const endDate = new Date();
      endDate.setHours(23, 59, 59, 0);
      endDate.setMonth(endDate.getMonth());
      this.dateRange.endDate = endDate.toISOString();

      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setHours(0, 0, 0, 0);
      this.dateRange.startDate = startDate.toISOString();

      this.minDate = new Date('2022-06-21T00:00:00').toISOString();
    },
    getPath() {
      return this.slugPathFromUrl || '/';
    },
    updateValues(dateRange) {
      this.dateRange = {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString(),
      };
      this.fetchStats();
      this.getThePageData();
      this.getTheProjectAndFolderData();
    },
    async fetchStats() {
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: { isIndicatorLoading: true },
      });

      try {
        this.calculateDaysAndPercentages();

        // Fetch global stats
        try {
          const fathomGlobalStats = await this.getFathomGlobalStats();
          const pirschGlobalStats = await this.getPirschGlobalStats();
          const pirschTimeOnPage = await this.getPirschTimeOnPage();
          this.globalStats = this.mergeGlobalStats(
            fathomGlobalStats, pirschGlobalStats, pirschTimeOnPage,
          );
          this.queryErrors.globalStats = false;
        } catch (error) {
          console.error('Error fetching global stats:', error);
          this.queryErrors.globalStats = true;
        }

        // Fetch reads per day data
        try {
          const fathomReadsPerDay = await this.getFathomReadsPerDay();
          const pirschReadsPerDay = await this.getPirschReadsPerDay();
          this.readsPerDayObject = this.mergeReadsPerDay(fathomReadsPerDay, pirschReadsPerDay);
          this.readsPerDayKey += 1;

          this.queryErrors.readsPerDayObject = false;
        } catch (error) {
          console.error('Error fetching reads per day:', error);
          this.queryErrors.readsPerDayObject = true;
        }

        // Fetch referrers data
        try {
          const fathomReferrers = await this.getFathomReferrers();
          const pirschReferrers = await this.getPirschReferrers();
          this.mergedReferrers = this.mergeReferrers(fathomReferrers, pirschReferrers);
          this.queryErrors.referrers = false;
        } catch (error) {
          console.error('Error fetching referrers:', error);
          this.queryErrors.referrers = true;
        }

        // Fetch device data
        try {
          const fathomDevices = await this.getFathomDevices();
          const pirschDevices = await this.getPirschDevices();
          this.mergedDevices = this.mergeDevices(fathomDevices, pirschDevices);
          this.queryErrors.devices = false;
        } catch (error) {
          console.error('Error fetching devices:', error);
          this.queryErrors.devices = true;
        }

        // Fetch countries data
        try {
          const fathomCountries = await this.getFathomCountries();
          const pirschCountries = await this.getPirschCountries();
          this.countries = this.mergeCountries(fathomCountries, pirschCountries);
          this.queryErrors.countries = false;
        } catch (error) {
          console.error('Error fetching countries:', error);
          this.queryErrors.countries = true;
        }
      } catch (error) {
        console.error('General Error while fetching data:', error);
      } finally {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: { isIndicatorLoading: false },
        });
      }
    },
    async getFathomGlobalStats() {
      if (new Date(this.dateRange.startDate) < this.switchDate) {
        const result = await this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'visits,pageviews,avg_duration,bounce_rate',
              filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate < this.switchDate.toISOString()
                ? this.dateRange.endDate
                : this.switchDate.toISOString(),
              timezone: this.timezone,
            },
          },
        });
        return result.data.getFathomData.result[0];
      }
      return null;
    },
    async getPirschGlobalStats() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/total',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    async getPirschTimeOnPage() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/duration/session',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
                scale: 'year',
              },
            },
          },
        });
        if (result.data.getPirschData.result) {
          let addedTime = 0;
          let counter = 0;
          result.data.getPirschData.result.forEach((item) => {
            if (item.average_time_spent_seconds) {
              addedTime += item.average_time_spent_seconds;
              counter += 1;
            }
          });
          return (counter > 0)
            ? (Math.round(addedTime)) / counter
            : 0;
        }
        return 0;
      }
      return null;
    },
    mergeGlobalStats(fathomData, pirschData, pirschTimeOnPage) {
      const fathomVisitors = parseInt(fathomData?.visits || 0, 10);
      const pirschVisitors = parseInt(pirschData?.visitors || 0, 10);
      const visitors = fathomVisitors + pirschVisitors || '-';
      const views = (parseInt(fathomData?.pageviews || 0, 10) + parseInt(pirschData?.views || 0, 10)) || '-';

      const fathomAvgTime = parseFloat(fathomData?.avg_duration || 0);
      const pirschAvgTime = parseFloat(pirschTimeOnPage);

      // Calculate average time considering both days and visitors
      const avgTime = this.calculateWeightedAverage(
        fathomAvgTime,
        pirschAvgTime,
        fathomVisitors * this.fathomDays,
        pirschVisitors * this.pirschDays,
      );

      // Calculate bounce rate considering both days and visitors
      const fathomBounceRate = parseFloat(fathomData?.bounce_rate || 0);
      const pirschBounceRate = parseFloat(pirschData?.bounce_rate || 0);

      const bounceRate = this.calculateWeightedAverage(
        fathomBounceRate,
        pirschBounceRate,
        fathomVisitors * this.fathomDays,
        pirschVisitors * this.pirschDays,
      );

      return {
        visitors,
        views,
        avgTime,
        bounceRate: `${Math.round(bounceRate * 100)}%`,
      };
    },
    getItemPathId() {
      switch (this.itemType) {
        case 'folder':
          return `/f${this.itemId}`;
        case 'project':
          return `/pr${this.itemId}`;
        default:
          return '/';
      }
    },
    calculateDaysAndPercentages() {
      const start = new Date(this.dateRange.startDate);
      const end = new Date(this.dateRange.endDate);
      const switchDate = new Date(this.switchDate);

      const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;

      if (end <= switchDate) {
        this.fathomDays = totalDays;
        this.pirschDays = 0;
      } else if (start >= switchDate) {
        this.fathomDays = 0;
        this.pirschDays = totalDays;
      } else {
        this.fathomDays = Math.ceil((switchDate - start) / (1000 * 60 * 60 * 24));
        this.pirschDays = totalDays - this.fathomDays;
      }

      this.fathomPercentage = ((this.fathomDays / totalDays) * 100).toFixed(2);
      this.pirschPercentage = ((this.pirschDays / totalDays) * 100).toFixed(2);
    },
    async getFathomReadsPerDay() {
      if (new Date(this.dateRange.startDate) < this.switchDate) {
        const result = await this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'pageviews',
              date_grouping: 'day',
              filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate < this.switchDate.toISOString()
                ? this.dateRange.endDate
                : this.switchDate.toISOString(),
              timezone: this.timezone,
            },
          },
        });
        return result.data.getFathomData.result;
      }
      return null;
    },
    async getPirschReadsPerDay() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/visitor',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    mergeReadsPerDay(fathomData, pirschData) {
      const mergedData = [];
      const switchDateStr = this.switchDate.toISOString().split('T')[0];

      if (fathomData) {
        fathomData.forEach((item) => {
          if (item.date < switchDateStr) {
            mergedData.push({
              label: item.date,
              value: parseInt(item.pageviews, 10),
            });
          }
        });
      }

      if (pirschData) {
        pirschData.forEach((item) => {
          if (item.day >= switchDateStr) {
            mergedData.push({
              label: item.day.split('T')[0],
              value: item.views,
            });
          }
        });
      }

      mergedData.sort((a, b) => a.label.localeCompare(b.label));
      return mergedData;
    },
    async getFathomReferrers() {
      if (new Date(this.dateRange.startDate) < this.switchDate) {
        const result = await this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'visits,pageviews,avg_duration,bounce_rate',
              filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
              field_grouping: 'referrer_hostname',
              sort_by: 'visits:desc',
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate < this.switchDate.toISOString()
                ? this.dateRange.endDate
                : this.switchDate.toISOString(),
              timezone: this.timezone,
            },
          },
        });
        return result.data.getFathomData.result;
      }
      return [];
    },
    async getPirschReferrers() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/referrer',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
                limit: 10,
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return [];
    },
    mergeReferrers(fathomData, pirschData) {
      const referrerMap = new Map();

      if (fathomData && fathomData.length > 0) {
        fathomData.forEach((item) => {
          const referrer = item.referrer_hostname || 'unknown';
          if (referrerMap.has(referrer)) {
            referrerMap.get(referrer).visitors += parseInt(item.visits, 10);
            referrerMap.get(referrer).views += parseInt(item.pageviews, 10);
          } else {
            referrerMap.set(referrer, {
              referrer,
              visitors: parseInt(item.visits, 10),
              views: parseInt(item.pageviews, 10),
            });
          }
        });
      }

      if (pirschData && pirschData.length > 0) {
        pirschData.forEach((item) => {
          const referrer = item.referrer || 'unknown';
          if (referrerMap.has(referrer)) {
            referrerMap.get(referrer).visitors += item.sessions;
            referrerMap.get(referrer).views += parseInt(item.visitors, 10);
          } else {
            referrerMap.set(referrer, {
              referrer,
              visitors: item.sessions,
              views: parseInt(item.visitors, 10),
            });
          }
        });
      }

      return Array.from(referrerMap.values())
        .sort((a, b) => b.visitors - a.visitors)
        .slice(0, 10);
    },
    async getFathomDevices() {
      if (new Date(this.dateRange.startDate) < this.switchDate) {
        const result = await this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'visits',
              field_grouping: 'device_type',
              filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate < this.switchDate.toISOString()
                ? this.dateRange.endDate
                : this.switchDate.toISOString(),
              timezone: this.timezone,
            },
          },
        });
        return result.data.getFathomData.result;
      }
      return [];
    },
    async getPirschDevices() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/platform',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    mergeDevices(fathomData, pirschData) {
      const deviceMap = new Map();

      // Process Fathom data
      const fathomTotal = fathomData.reduce((sum, item) => sum + parseInt(item.visits, 10), 0);
      fathomData.forEach((item) => {
        const deviceType = item.device_type.toLowerCase();
        const mappedType = deviceType === 'tablet' || deviceType === 'phone' ? 'mobile' : deviceType;
        const existingDevice = deviceMap.get(mappedType);
        if (existingDevice) {
          existingDevice.visitors += (parseInt(item.visits, 10) / fathomTotal) * this.fathomPercentage;
        } else {
          deviceMap.set(mappedType, {
            device: mappedType.charAt(0).toUpperCase() + mappedType.slice(1),
            visitors: (parseInt(item.visits, 10) / fathomTotal) * this.fathomPercentage,
          });
        }
      });

      // Process Pirsch data
      if (pirschData && typeof pirschData === 'object') {
        const pirschTotal = pirschData.platform_desktop + pirschData.platform_mobile + pirschData.platform_unknown;
        if (pirschTotal > 0) {
          const deviceMapping = {
            desktop: 'desktop',
            mobile: 'mobile',
            unknown: 'unknown',
          };

          Object.entries(deviceMapping).forEach(([pirschDevice, mappedDevice]) => {
            const count = pirschData[`platform_${pirschDevice}`];
            const percentage = (count / pirschTotal) * this.pirschPercentage;
            const existingDevice = deviceMap.get(mappedDevice);
            if (existingDevice) {
              existingDevice.visitors += percentage;
            } else {
              deviceMap.set(mappedDevice, {
                device: mappedDevice.charAt(0).toUpperCase() + mappedDevice.slice(1),
                visitors: percentage,
              });
            }
          });
        }
      }

      // Ensure all three categories exist
      ['desktop', 'mobile', 'unknown'].forEach((device) => {
        if (!deviceMap.has(device)) {
          deviceMap.set(device, {
            device: device.charAt(0).toUpperCase() + device.slice(1),
            visitors: 0,
          });
        }
      });

      // Merge and calculate final percentages
      const mergedDevices = Array.from(deviceMap.values()).map((item) => ({
        device: item.device,
        visitors: `${Math.round(item.visitors)}%`,
      }));

      // Sort by percentage (descending)
      return mergedDevices.sort((a, b) => parseInt(b.visitors, 10) - parseInt(a.visitors, 10));
    },
    async getFathomCountries() {
      if (new Date(this.dateRange.startDate) < this.switchDate) {
        const result = await this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'visits',
              filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
              field_grouping: 'country_code',
              sort_by: 'visits:desc',
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate < this.switchDate.toISOString()
                ? this.dateRange.endDate
                : this.switchDate.toISOString(),
              timezone: this.timezone,
            },
          },
        });
        return result.data.getFathomData.result;
      }
      return null;
    },
    async getPirschCountries() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/country',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
              },
            },
          },
        });
        return result.data.getPirschData.result;
      }
      return null;
    },
    mergeCountries(fathomData, pirschData) {
      const countryMap = new Map();
      let totalVisitors = 0;

      if (fathomData) {
        fathomData.forEach((item) => {
          const visitors = parseInt(item.visits, 10);
          countryMap.set(item.country_code.toLowerCase(), { name: item.country_code.toUpperCase(), visitors });
          totalVisitors += visitors;
        });
      }

      if (pirschData) {
        pirschData.forEach((item) => {
          const { visitors, country_code } = item;
          const countryCode = country_code.toLowerCase();
          if (countryMap.has(countryCode)) {
            countryMap.get(countryCode).visitors += visitors;
          } else {
            countryMap.set(countryCode, { name: country_code.toUpperCase(), visitors });
          }
          totalVisitors += visitors;
        });
      }

      const mergedCountries = Array.from(countryMap.values()).map((country) => {
        const percentage = Math.round((country.visitors / totalVisitors) * 100);
        return {
          name: country.name,
          readers: percentage === 0 ? `0% (${country.visitors})` : `${percentage}%`,
        };
      });

      return mergedCountries.sort((a, b) => parseInt(b.readers, 10) - parseInt(a.readers, 10));
    },
    async getThePageData() {
      try {
        let childPages;
        if (this.itemType === 'project') {
          const result = await this.$apollo.query({
            query: GetAllChildPageIdsOfProject,
            variables: { projectId: this.itemId },
            fetchPolicy: 'network-only',
          });
          childPages = result.data.projects[0]?.child_pages || [];
        } else {
          const result = await this.$apollo.query({
            query: GetAllChildPageIdsOfFolder,
            variables: { folderId: this.itemId },
            fetchPolicy: 'network-only',
          });
          childPages = result.data.folders[0]?.child_pages || [];
        }

        const fathomData = await this.getFathomDataForChildPages();
        const pirschData = await this.getPirschDataForChildPages();

        this.setChildPageData(childPages, fathomData, pirschData);
      } catch (error) {
        console.error('Error fetching page data:', error);
        this.queryErrors.pages = true;
      }
    },
    async getFathomDataForChildPages() {
      if (new Date(this.dateRange.startDate) < this.switchDate) {
        const result = await this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'visits,pageviews,uniques,avg_duration,bounce_rate',
              filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
              field_grouping: 'pathname',
              sort_by: 'pageviews:desc',
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate < this.switchDate.toISOString()
                ? this.dateRange.endDate
                : this.switchDate.toISOString(),
              timezone: this.timezone,
            },
          },
          fetchPolicy: 'network-only',
        });
        return result.data.getFathomData.result;
      }
      return [];
    },
    async getPirschDataForChildPages() {
      if (new Date(this.dateRange.endDate) >= this.switchDate) {
        const result = await this.$apollo.query({
          query: GetPirschData,
          variables: {
            obj: {
              endpoint: '/statistics/page',
              params: {
                id: this.pirschCode,
                from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
                to: this.dateRange.endDate.split('T')[0],
                pattern: this.getItemPathId(),
                include_avg_time_on_page: true,
              },
            },
          },
          fetchPolicy: 'network-only',
        });
        return result.data.getPirschData.result;
      }
      return [];
    },
    setChildPageData(childPages, fathomData, pirschData) {
      this.pages = childPages.map((childPage) => {
        const itemPath = `/${childPage.item_path.replace(/\./g, '/')}`;
        const fathomPageData = fathomData ? fathomData.find((item) => item.pathname.includes(childPage.slug)) : null;
        const pirschPageData = pirschData ? pirschData.find((item) => item.path === itemPath) : null;

        const imageObject = {
          imageUrl: this.getPageImageUrl(childPage.thumbnails),
          focalpoint: childPage.thumbnails?.image?.focalpoint
            ? `${childPage.thumbnails.image.focalpoint.x}% ${childPage.thumbnails.image.focalpoint.y}%`
            : null,
        };

        return {
          name: childPage.name,
          entries: this.mergeEntries(fathomPageData, pirschPageData),
          viewers: this.mergeViewers(fathomPageData, pirschPageData),
          views: this.mergeViews(fathomPageData, pirschPageData),
          avgReadingTime: this.mergeAvgReadingTime(fathomPageData, pirschPageData),
          bounceRate: this.mergeBounceRate(fathomPageData, pirschPageData),
          imageObject,
        };
      });

      this.pageTableKey += 1;
    },
    mergeEntries(fathomData, pirschData) {
      const fathomEntries = fathomData ? parseInt(fathomData.visits, 10) : 0;
      const pirschEntries = pirschData ? pirschData.visitors : 0;
      return fathomEntries + pirschEntries;
    },
    mergeViewers(fathomData, pirschData) {
      const fathomViewers = fathomData ? parseInt(fathomData.uniques, 10) : 0;
      const pirschViewers = pirschData ? pirschData.sessions : 0; // TODO: not sure if this is the right mapping
      return fathomViewers + pirschViewers;
    },
    mergeViews(fathomData, pirschData) {
      const fathomViews = fathomData ? parseInt(fathomData.pageviews, 10) : 0;
      const pirschViews = pirschData ? pirschData.views : 0;
      return fathomViews + pirschViews;
    },
    mergeAvgReadingTime(fathomData, pirschData) {
      const fathomTime = fathomData ? parseFloat(fathomData.avg_duration) : 0;
      const pirschTime = pirschData ? pirschData.average_time_spent_seconds : 0;
      const fathomWeight = fathomData ? parseInt(fathomData.pageviews, 10) : 0;
      const pirschWeight = pirschData ? pirschData.views : 0;
      const totalWeight = fathomWeight + pirschWeight;

      if (totalWeight === 0) return 0;
      return ((fathomTime * fathomWeight) + (pirschTime * pirschWeight)) / totalWeight;
    },
    mergeBounceRate(fathomData, pirschData) {
      const fathomRate = fathomData ? parseFloat(fathomData.bounce_rate) || 0 : 0;
      const pirschRate = pirschData ? pirschData.bounce_rate : 0;
      const fathomWeight = fathomData ? parseInt(fathomData.visits, 10) : 0;
      const pirschWeight = pirschData ? pirschData.sessions : 0;
      const totalWeight = fathomWeight + pirschWeight;

      if (totalWeight === 0) return '0%';
      const mergedRate = ((fathomRate * fathomWeight) + (pirschRate * pirschWeight)) / totalWeight;
      return `${Math.round(mergedRate * 100)}%`;
    },
    getPageImageUrl(thumbnail) {
      let cloudImageUrl = null;
      if (thumbnail?.image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          thumbnail.image.url,
          {
            width: 210,
            height: 150,
          },
          thumbnail.image,
        );
      }
      return (thumbnail?.image?.url)
        ? `url(${cloudImageUrl})`
        : `url(${thumbDefault})`; // Updated to use imported image
    },
    async getTheProjectAndFolderData() {
      if (this.itemType !== 'folder') return;

      this.$apollo.query({
        query: GetAllFoldersAndProjectsByFolderId,
        variables: {
          folderId: this.itemId,
        },
        fetchPolicy: 'network-only',
      }).then((result) => {
        const childItems = [
          ...result.data.folders.map((folder) => ({ ...folder, type: 'folder' })),
          ...result.data.projects.map((project) => ({ ...project, type: 'project' })),
        ];

        Promise.allSettled(childItems.flatMap((item) => [
          this.getFathomDataForChildItem(item),
          this.getPirschDataForChildItem(item),
        ])).then((results) => {
          // Process results and update projectOrFolders
          this.projectOrFolders = childItems.map((item) => {
            // find the result item by the item_path
            const [fathomData, pirschData] = results
              .filter((r) => r.value && r.value.childPath === item.item_path)
              .map((r) => r.value);

            const mergedData = this.mergeItemData(fathomData, pirschData);

            return {
              name: item.name,
              type: item.type,
              image: this.getItemImageUrl(item),
              entries: mergedData.entries,
              views: mergedData.views,
              avgReadingTime: mergedData.avgReadingTime,
              bounceRate: mergedData.bounceRate,
            };
          });

          // Trigger a re-render of the table
          this.folderOrProjectTableKey += 1;
        }).catch((error) => {
          console.error('Error fetching Analytics data:', error);
          this.queryErrors.projectOrFolders = true;
          this.projectOrFolders = childItems.map((item) => ({
            name: item.name,
            type: item.type,
            image: this.getItemImageUrl(item),
            entries: 0,
            views: 0,
            avgReadingTime: 0,
            bounceRate: 0,
          }));
        });
      });
    },
    getItemImageUrl(item) {
      if (item.type === 'folder') {
        return null; // Folders don't have images, we'll use an icon instead
      }
      let cloudImageUrl = null;
      if (item.thumbnails?.cover_image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          item.thumbnails.cover_image.url,
          { width: 210, height: 150 },
          item.thumbnails.cover_image,
        );
      }
      return (item.thumbnails?.cover_image?.url)
        ? `url(${cloudImageUrl})`
        : `url(${thumbDefault})`;
    },
    getFathomDataForChildItem(childItem) {
      if (new Date(this.dateRange.startDate) >= this.switchDate) {
        return Promise.resolve(null);
      }

      const { childSlugPath } = childItem;
      if (!childSlugPath) {
        return Promise.resolve(null);
      }

      return this.$apollo.query({
        query: GetFathomData,
        variables: {
          obj: {
            aggregates: 'visits,pageviews,uniques,avg_duration,bounce_rate',
            filters: `[{"property":"pathname","operator":"is like","value":"${childSlugPath}"},{"property":"hostname","operator":"is","value":"${this.groupDomain}"}]`,
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate < this.switchDate.toISOString()
              ? this.dateRange.endDate
              : this.switchDate.toISOString(),
            timezone: this.timezone,
          },
        },
        fetchPolicy: 'network-only',
      }).then((response) => {
        const result = response.data.getFathomData.result[0];
        return {
          visits: parseInt(result.visits, 10) || 0,
          pageviews: parseInt(result.pageviews, 10) || 0,
          uniques: parseInt(result.uniques, 10) || 0,
          avg_duration: parseFloat(result.avg_duration) || 0,
          bounce_rate: parseFloat(result.bounce_rate) || 0,
          childPath: childItem.item_path,
        };
      }).catch((error) => {
        console.error('Error fetching Fathom data for child item:', error);
        return {
          visits: 0,
          pageviews: 0,
          uniques: 0,
          avg_duration: 0,
          bounce_rate: 0,
          childPath: childItem.item_path,
        };
      });
    },
    getPirschDataForChildItem(childItem) {
      if (new Date(this.dateRange.endDate) < this.switchDate) {
        return Promise.resolve(null);
      }

      const itemPath = `/${childItem.item_path.replace(/\./g, '/')}`;

      if (!itemPath || !this.pirschCode) {
        return Promise.resolve(null);
      }

      return this.$apollo.query({
        query: GetPirschData,
        variables: {
          obj: {
            endpoint: '/statistics/total',
            params: {
              id: this.pirschCode,
              from: this.dateRange.startDate > this.switchDate.toISOString() ? this.dateRange.startDate.split('T')[0] : this.switchDate.toISOString().split('T')[0],
              to: this.dateRange.endDate.split('T')[0],
              pattern: itemPath,
              include_avg_time_on_page: true,
            },
          },
        },
        fetchPolicy: 'network-only',
      }).then((response) => {
        if (!response.data || !response.data.getPirschData || !response.data.getPirschData.result) {
          throw new Error('Invalid response structure from Pirsch API');
        }
        const { result } = response.data.getPirschData;
        return {
          visits: parseInt(result.visitors, 10) || 0,
          pageviews: parseInt(result.views, 10) || 0,
          uniques: parseInt(result.sessions, 10) || 0,
          avg_duration: result.average_time_spent_seconds || 0,
          bounce_rate: parseFloat(result.bounce_rate) || 0,
          childPath: childItem.item_path,
        };
      }).catch((error) => {
        console.error('Error fetching Pirsch data for child item:', error);
        return {
          visits: 0,
          pageviews: 0,
          uniques: 0,
          avg_duration: 0,
          bounce_rate: 0,
          childPath: childItem.item_path,
        };
      });
    },
    mergeItemData(fathomData, pirschData) {
      const fathomEntries = fathomData ? parseInt(fathomData.visits, 10) : 0;
      const pirschEntries = pirschData ? pirschData.visits : 0;
      const entries = fathomEntries + pirschEntries;

      const fathomViews = fathomData ? parseInt(fathomData.pageviews, 10) : 0;
      const pirschViews = pirschData ? pirschData.pageviews : 0;
      const views = fathomViews + pirschViews;

      const fathomAvgTime = fathomData ? parseFloat(fathomData.avg_duration) : 0;
      const pirschAvgTime = pirschData ? pirschData.avg_duration : 0;
      const avgReadingTime = this.calculateWeightedAverage(
        fathomAvgTime,
        pirschAvgTime,
        fathomViews,
        pirschViews,
      );

      const fathomBounceRate = fathomData ? parseFloat(fathomData.bounce_rate) : 0;
      const pirschBounceRate = pirschData ? pirschData.bounce_rate : 0;
      const bounceRate = this.calculateWeightedAverage(
        fathomBounceRate,
        pirschBounceRate,
        fathomEntries,
        pirschEntries,
      );

      return {
        entries: entries || 0,
        views: views || 0,
        avgReadingTime: avgReadingTime || 0,
        bounceRate: bounceRate ? `${Math.round(bounceRate * 100)}%` : 0,
      };
    },
    calculateWeightedAverage(value1, value2, weight1, weight2) {
      const totalWeight = weight1 + weight2;
      if (totalWeight === 0) return 0;
      return ((value1 * weight1) + (value2 * weight2)) / totalWeight;
    },
  },
  filters: {
    timeString(value) {
      if (!value) return '0';
      const totalSeconds = Math.floor(value);
      const seconds = totalSeconds % 60;
      const minutes = Math.floor(totalSeconds / 60);
      return (minutes > 0)
        ? `${minutes}min ${seconds}s`
        : `${seconds}s`;
    },
    inputDate(value) {
      if (!value) return value;
      const timestamp = new Date(value);
      if (!timestamp) return value;
      const month = timestamp.getMonth() + 1;
      const day = timestamp.getDate();
      return `${day}.${month}`;
    },
  },
  mounted() {
    this.fetchStats();
    this.getThePageData();
    this.getTheProjectAndFolderData();
  },
};
</script>

<style scoped lang="scss">
/deep/ .daterangepicker .calendar-table td, /deep/ .daterangepicker .calendar-table th {
  border-radius: 0;
}

h3 {
  line-height: 38px;
}

.domain-select {
  max-width: 350px;

  select {
    width: 100%;
  }
}

h4, h5 {
  text-transform: uppercase;
  font-weight: 600;
}

h4 {
  color: #fff;
}

.global-stats {
  h5 {
    font-size: 13px;
    color: #b9b9b9;
  }

  div {
    font-size: 33px;
    font-weight: 400;
    color: #fff
  }
}

.table-dark {
  background-color: transparent;
}

/deep/ table {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &.image:first-child {
        padding: 0;
        max-width: 50px;
      }

      &.center {
        text-align: center;
        font-weight: 700;
      }

      &:nth-child(4) {
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: block;
            padding: 0 5px;
            margin-right: 6px;
            font-size: 13px;
            font-weight: 700;
            color: #44484C;
            background-color: #B9B9B9;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .cell-background-image {
    width: 100%;
    height: 100%;
    min-height: 36px;
    min-width: 50px;
    background-color: #777C7F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cell-folder {
    background: rgba(83, 220, 134, 0.7);

    svg {
      fill: white;
    }
  }
}

hr {
  margin-top: 0;
}

.dropdown-icon {
  color: #A9B1B5;
  position: absolute;
  right: 10px;
  top: 10px;
}

/deep/ .reportrange-text {
  position: relative;
  background: #53575B;
  color: #B9B9B9;
  border: 1px solid #707070;
  padding: 10px 50px 2px 15px;
  line-height: 16px;
}

/deep/ #line-chart {
  min-height: 400px;
}

#content .query-error {
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  background-color: #53575B;
  border: 1px solid rgba(220, 83, 83, 0.7);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  font-size: 15px;
  padding: 7px 8px;
}
.country-table /deep/ tr > td {
  text-transform: uppercase;
}

.stats-breakdown {
  color: #b9b9b9;

  h5 {
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .stat-value {
    font-weight: bold;
    color: #fff;
  }
}

.table-simple {
  width: 100%;

  th, td {
    padding: 10px;
    text-align: left;
  }

  th {
    font-weight: bold;
    text-transform: uppercase;
  }

  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cell-folder {
  background: rgba(83, 220, 134, 0.7);

  svg {
    fill: white;
  }
}
</style>
